import axios from 'axios';
import qs from 'qs';
import { runAndHandleInvalidAuth } from '@/helpers/api/auth';
import useDataSourceStore from '@/stores/dataSource';
import { joinPaths } from '@/helpers/utils';

axios.defaults.withCredentials = true;

interface RequestMoreOptions extends RequestSideEffectsMoreOptions {
  baseUrl?: string,
}

interface RequestSideEffectsMoreOptions {
  callbackAfterResponse?: Function,
  isLogoutOnInvalidAuth?: boolean,
  config?: object
}

export default function useApi() {
  const { activeDataSourceBaseUrl } = useDataSourceStore();
  const baseUrlDefault = activeDataSourceBaseUrl || "";

  async function postUrlEncoded(
    path: string,
    data: object,
    moreOptions?: RequestMoreOptions
  ) {
    const { responseBody, response } = await fetchResponseBody({
      method: 'POST',
      path,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
    }, moreOptions);

    return { responseBody, response };
  }

  async function post(
    path: string,
    data: object,
    moreOptions?: RequestMoreOptions
  ) {
    const { responseBody, response } = await fetchResponseBody({
      method: 'POST',
      path,
      data,
    }, moreOptions);

    return { responseBody, response };
  }

  async function put(
    path: string,
    data: object,
    moreOptions?: RequestMoreOptions
  ) {
    const { responseBody, response } = await fetchResponseBody({
      method: 'PUT',
      path,
      data,
    }, moreOptions);

    return { responseBody, response };
  }

  async function get(
    path: string,
    moreOptions?: RequestMoreOptions & { params?: Record<string, any> } 
  ) {
    const { params, ...otherOptions } = moreOptions || {};
    if (params) {
      const queryString = new URLSearchParams(params).toString();
      path += `?${queryString}`; 
    }
    const { responseBody, response } = await fetchResponseBody(
      {
        method: 'GET',
        path,
      },
      otherOptions
    );
    return { responseBody, response };
  }

  // TODO (cleaner code): think about a more consistent name for "deleteRequest" function as we can't name it "delete" [DSP-N26EJE9H]
  // TODO (cleaner code): think if there's a better way to resolve redundancy between "deleteRequest", "get", "post" [DSP-N26EKU8b]
  async function deleteRequest(
    path: string,
    moreOptions?: RequestMoreOptions,
  ) {
    const { responseBody, response } = await fetchResponseBody({
      method: 'DELETE',
      path,
    }, moreOptions);
    return { responseBody, response };
  }
  
  async function fetchResponseBody(
    {
      method,
      path,
      ...restOfOptions
    }: FetchResponseBodyOptions,
    {   // more options
      baseUrl = baseUrlDefault,
      config = {},   // config to be passed to requester function (e.g. axios)
      ...restOfMoreOptions
    }: RequestMoreOptions = {}
  ) {
    const url = new URL( joinPaths(baseUrl,path) ).toString();
    const options = {
      method: method.toUpperCase(),
      url,
      ...config,
      ...restOfOptions
    };
  
    const { responseBody, response } = await fetchReposneWithSideEffects(options, restOfMoreOptions);
    return { responseBody, response };
  }

  return {
    postUrlEncoded,
    post,
    put,
    get,
    deleteRequest,
    fetchResponseBody
  }
}


// Side utlity functions
export function getModelFromViewCode(viewBodyText: string) {
  const regex = /(?<=model = )[^;]*/g;
  const dataStr = String( viewBodyText.match(regex) );
  const dataObj = JSON.parse(dataStr);
  return dataObj;
}



// Side helpers

interface FetchResponseBodyOptions {
  method: string,
  path: string,
  headers?: object,
  data?: object | string,
}


async function fetchReposneWithSideEffects(
  options,
  {
    callbackAfterResponse,
    isLogoutOnInvalidAuth,
  }: RequestSideEffectsMoreOptions) {
  
  
  // fetch reponse, and if isLogoutOnInvalidAuth is true, then handle logging out user on invalid auth side effect.
  const response = await fetchResponseAndHandleAuth(options, {isLogoutOnInvalidAuth});

  try {

    // if callbackAfterResponse side effect is defined, call it
    if(typeof callbackAfterResponse == 'function') {
      callbackAfterResponse(response);
    }

    const responseBody = response?.data;
    return { responseBody, response };
  } catch(err: any) {
    console.error(err);
    throw err;
  }
}


async function fetchResponseAndHandleAuth(options, {isLogoutOnInvalidAuth = false}) {
  let response;
  if (isLogoutOnInvalidAuth) {
    response = await runAndHandleInvalidAuth(() => fetchResponse(options))
  } else {
    response = await fetchResponse(options)
  }
  return response;
}

async function fetchResponse(options) {
  try {
    const response = await axios({...options, withCredentials: true});
    return response;
  }
  catch (err :any ){
    return err?.response 
    console.log("err")
    console.log(err)

  }

}
