<template>
    <div v-if="isMaintenanceActive" class="maintenance-banner">
      🚧 The site is currently under maintenance. Some features may be unavailable. 🚧
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { fetchMaintenanceStatus } from '../../helpers/api/maintenance/maintenance';
  
  const isMaintenanceActive = ref(false);
  
  // Polling function for the maintenance status
  const pollMaintenanceStatus = async () => {
    try {
      const status = await fetchMaintenanceStatus();
      isMaintenanceActive.value = status.isScheduled;
    } catch (error) {
      console.error('Error fetching maintenance status:', error);
    }
  };
  
  // Set up polling on component mount
  onMounted(() => {
    pollMaintenanceStatus(); // Fetch once on load
    setInterval(pollMaintenanceStatus, 30000); // Poll every 30 seconds
  });
  </script>
  
  <style scoped>
  .maintenance-banner {
    background-color: #ffc107;
    color: #212529;
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    /* position: fixed; */
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  </style>
  