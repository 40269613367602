import useApi from "../api";

// Fetch the current maintenance status
export async function fetchMaintenanceStatus() {
  const { get } = useApi();
  const { responseBody } = await get('/api/maintenance/status', {
    isLogoutOnInvalidAuth: true,
  });
  return responseBody;
}
