import { fetchList } from "@/helpers/api/list/fetch";
import { computed, reactive, ref, watch, watchEffect} from "vue";
import useApiSWR from "@/helpers/api/swr";
import useProgressBar from "@/components/ProgressBar/composables/progressBar";


interface ItemsListOptions {
  apiPathsConfig?: any,
  initializeApiPath?: string,
  searchApiPath?: string,
  filtersDisplayConfig?: any[],
  resultsManipulationCallback?: Function
  tableViewConfig?: any
  isEnableAllLimitOption?: boolean
  entityItemsNamePlural?: string
  entityItemName?: string
  columnsToHide?:string[]
}

// TODO (UI / UX improvement): think about a way to keep a live "filters config" and "columns config" and also "selected filters"

export function useItemsListHelpers({
  apiPathsConfig,
  initializeApiPath,
  searchApiPath,
  filtersDisplayConfig,
  resultsManipulationCallback,    // TODO: think if there's a better approach that can acheive the immutability principle (for better testability later)
  tableViewConfig,
  isEnableAllLimitOption,
  entityItemsNamePlural,
  entityItemName,
  columnsToHide
}: ItemsListOptions = {}) {
  const offset = ref(0);
  const limit = ref(25);
  const sortBy = reactive({
    fieldName: null,
    isDescending: true
  });

  // TODO: think about importing types from "@\helpers\api\media\helpers\listing\types" — N2FGg75A
  const searchTerm = ref<string>("");
  const queryFieldNames = reactive<string[]>([]);    // fields to search within
  
  const filters = reactive({}); // for selected filters values
  const filtersConfig = reactive([]); // for filters available options & display order

  const columnsConfig = reactive([]);

  const listViewType = ref<"grid" | "table">("table");   // TODO: think about moving to the view switcher component
    // kept if needed for lists with multiple view types

  const isLoading = ref(false);
  const isLoaderLoading = ref(false); // TODO: think about a better descriptive / readable name (also differentiating the purpose of each state)
  const isReloading = ref(false);

  const isListInitialized = ref(false);
  const requestsCount = ref(0);

  const filteredListItems = computed(() =>{
    if (listResponse.value == null) return [];
    return listResponse.value.results;
  });

  const listHelperBasicInfo = reactive({});
    // Examples of "list helper basic info" are:
      // • "creator" to get "fitment" data necessary for creating new playlist.

  watch([sortBy, searchTerm, filters], ()=> {
    offset.value = 0 // reset offset (and consequently reset page number to first)
  })

  function prepareFiltersConfigFromResponse( response: any ) {
    // response should be coming from initializing api. (e.g. /media, /playlists, /players, etc.)
    const filtersConfig_fromResponse = response.filters;
    // TODO: we need to figure out a way to keep META data filters brought from the API (because they're dynamic when a user adds / remove a tag these filters change)
      // Also we need to figure out a consistent way to get the field name
    const filtersConfig_fromResponse_remaining = [...filtersConfig_fromResponse];    // contains remaining not prepared yet filters (that weren't mentioned in the filtersDisplayConfig)
    const filtersConfigPrepared = filtersDisplayConfig.map((filterDisplayConfig, index) => {
      const filterConfig_fromResponse_Index = filtersConfig_fromResponse_remaining.findIndex((item) => item.name === filterDisplayConfig.name);
      const filterConfig_fromResponse = filtersConfig_fromResponse_remaining[filterConfig_fromResponse_Index];
      filtersConfig_fromResponse_remaining.splice(filterConfig_fromResponse_Index, 1);

      if (filterConfig_fromResponse == null) return null; // TODO: think if we need to throw an error
      const filterConfig = {
        ...filterConfig_fromResponse,
        ...filterDisplayConfig
      };
      const filterName = filterConfig.name;
      filters[filterName] = filterConfig.selectedOptions[0]; // TODO: add support later for reading & setting multi-select options if already supported by the API (bec. it seems not)
      return filterConfig;
    })
    // add other filters (metadata and tag filters) (if exist)
    filtersConfig_fromResponse_remaining.forEach((filterConfig_fromResponse) => {
      // add tag filter
      if(filterConfig_fromResponse.name.startsWith('FILTER_TAG')){
        const filterConfigPrepared = {
          "label": "Tags",
          "type": "collapsibleSelect",
        }
        Object.assign(filterConfigPrepared,filterConfig_fromResponse)
        const filterName = filterConfigPrepared.name;
        filters[filterName] = filterConfigPrepared.selectedOptions[0];
        filtersConfigPrepared.push(filterConfigPrepared);
      }
      // add metadata filter
      if(!filterConfig_fromResponse.name.startsWith('FILTER_METADATA_')) {  // skip if not a metadata filter
        return;
      }
      let filterLabel;
      { // prepare name
        const regex = /(?<=^FILTER_METADATA_).*/;
        const label_notPrepared = filterConfig_fromResponse.name.match(regex)[0];
        const words_notPrepared = label_notPrepared.split(' ');
        const words_prepared = words_notPrepared.map((word: string) => {
          const word_prepared = word.charAt(0).toUpperCase() + word.slice(1);
          return word_prepared;
        });
        filterLabel = words_prepared.join(' ');
      }
      const filterConfigPrepared = {
        "label": filterLabel,
        "type": "collapsibleSelect"
      }
      Object.assign(filterConfigPrepared, filterConfig_fromResponse);
      const filterName = filterConfigPrepared.name;
      filters[filterName] = filterConfigPrepared.selectedOptions[0]; // TODO: add support later for reading & setting multi-select options if already supported by the API (bec. it seems not)  
      filtersConfigPrepared.push(filterConfigPrepared);
    })
    Object.assign(filtersConfig, filtersConfigPrepared);
  }

  function prepareColumnsConfigFromResponse( response: any ) {
    // response should be coming from initializing api. (e.g. /media, /playlists, /players, etc.)
    let columnsConfig_fromResponse = response.columns;
    
    if(columnsToHide?.length)
    {
      columnsConfig_fromResponse = columnsConfig_fromResponse.filter((column :any )=> !columnsToHide.includes(column.name));
    }
 
    Object.assign(columnsConfig, columnsConfig_fromResponse);
  }
  
  function prepareQueryFieldNamesFromResponse( response: any ) {
    const queryFieldNames_fromResponse = response.query.fieldNames;
    queryFieldNames.push(...queryFieldNames_fromResponse);
  }

  function prepareInitialSortByFromResponse( response: any ) {
    const sortsConfig_fromResponse = response.sorts[0];
    if(sortsConfig_fromResponse == null) return;
    Object.assign(sortBy, sortsConfig_fromResponse);
  }

  function prepareListHelperBasicInfo( response: any ) {
    Object.assign(listHelperBasicInfo, {creator: response.creator});
  }

  const totalItems = computed(() => {
    if (listResponse.value == null) {
      return 0;
    }
    return listResponse.value.totalResults;
  });

  const totalPages = computed(() => {
    if(totalItems.value ===  0) {
      return 1
    } else {
      return Math.ceil(totalItems.value / (limit.value == 0 ? totalItems.value : limit.value));
    }
  })
  
  const currentPageNumber = computed(() => {
    // get current page from offset and limit
    return (Math.floor((offset.value) / (limit.value == 0 ? totalItems.value : limit.value)) + 1) || 1;
  });
  const lastPageNumber = computed(() => totalPages.value)
  
  function setCurrentPageNumber(pageNumber) {
    // set offset from current page
    setOffsetFromPageNumber(pageNumber);
  }
  
  function setOffsetFromPageNumber(pageNumber) {
    const newOffset = (pageNumber - 1) * limit.value;
    offset.value = newOffset;
  }
  
  const listingOptions = computed(() => ({
    initializeApiPath: initializeApiPath || apiPathsConfig.initialize,
    searchApiPath: searchApiPath || apiPathsConfig.search,
    isInitialize: !isListInitialized.value,
    currentPageNumber: currentPageNumber.value,
    searchTerm: searchTerm.value,
    queryFieldNames,
    limit: limit.value,
    offset: offset.value,
    sortBy,
    filters,
  }))

  const swrCacheKey = computed(() => { return {key: 'fetchList', sideComposite: listingOptions.value}});
  
  const {
    data: listResponse,
    mutate: mutateList,
    isValidating: isSWRValidating,
    isLoading: isSWRLoading,
    error
  } = useApiSWR(() => swrCacheKey.value, loadList);
  if(listResponse.value != null) {
    initiateListHelperSetupFromResponseIfNotInitiated(listResponse.value);
  }

  watchEffect(() => {
    isLoading.value = isSWRLoading.value;
    isReloading.value = (isSWRValidating.value && isLoaderLoading.value) && !isSWRLoading.value;
  })

  const progressBar = useProgressBar();
  watch([isLoading, isLoaderLoading, isReloading, isSWRValidating], () => {
    if(isLoading.value || isLoaderLoading.value || isReloading.value || isSWRValidating.value) {
      progressBar.start();
    } else {
      progressBar.finish();
    }
  })
  
  function initiateListHelperSetupFromResponseIfNotInitiated(listResponse) {
    if(!isListInitialized.value) {
      try {
        prepareFiltersConfigFromResponse(listResponse)
        prepareColumnsConfigFromResponse(listResponse)
        prepareQueryFieldNamesFromResponse(listResponse)
        prepareInitialSortByFromResponse(listResponse)
        prepareListHelperBasicInfo(listResponse)
        isListInitialized.value = true;
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function loadList() {
    isLoaderLoading.value = true;
    const listResponse = await fetchList(listingOptions.value);
    requestsCount.value += 1;
    isLoaderLoading.value = false;
    initiateListHelperSetupFromResponseIfNotInitiated(listResponse);
    // Prepare misc. fields
    // TODO: think about using "resultsManipulationCallback" here (also think if there's a better / cleaner approach to this) — N2OFDeAW
    if(resultsManipulationCallback) {
      listResponse.results.forEach(resultsManipulationCallback);
    }
    return listResponse;
  }
  
  async function reloadList() {
    await mutateList();
  }

  return {
    filteredListItems,
    offset,
    limit,
    totalItems,
    currentPageNumber,
    totalPages,
    lastPageNumber,
    filters,
    filtersConfig,
    columnsConfig,
    searchTerm,
    queryFieldNames,
    listViewType,
    sortBy,
    setCurrentPageNumber,
    isLoading,
    isSWRValidating: isReloading,
    loadList,
    reloadList,
    error,
    listResponse,
    mutateList,
    listHelperBasicInfo,
    tableViewConfig,
    isEnableAllLimitOption,
    entityItemsNamePlural,
    entityItemName,
    apiPathsConfig
  }
}



export default useItemsListHelpers;